<template>
  <div class="meta"></div>
</template>

<script type="text/babel">
export default {
  name: "Meta",
  props: {
    title: String,
    description: String,
  },
  metaInfo() {
    return {
      title: this.title + " | TeStUp",
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          itemprop: "name",
          content: this.title + " | TeStUp",
        },
        {
          property: "og:title",
          content: this.title + " | TeStUp",
        },
        {
          itemprop: "description",
          content: this.description,
        },
        {
          property: "og:description",
          content: this.description,
        },
      ],
    };
  },
};
</script>
