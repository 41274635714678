<template>
  <div class="blockcontent">
    <b-loading :is-full-page="true" :active="isLoading"></b-loading>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut animate__slow"
    >
      <b-notification
        type="is-info"
        has-icon
        icon="database-off"
        aria-close-label="Close notification"
        role="alert"
        :closable="closenotification"
        v-if="isError"
      >
        <p class="is-size-6">
          Diese Seite ist gerade nicht erreichbar.<br />
          <small>Sorry. Bitte probiere es später noch einmal.</small>
        </p>
      </b-notification>
    </transition>
    <div class="columns">
      <div class="column is-12 is-10-desktop is-offset-1-desktop">
        <transition-group
          name="custom-classes-transition"
          enter-active-class="animate__animated animate__fadeIn animate__slow"
          leave-active-class="animate__animated animate__fadeOut animate__slow"
        >
          <div class="block" v-for="block in data.results" :key="block.id">
            <div v-if="block.type === 'heading_1'">
              <h1 class="is-size-2 is-size-3-mobile mt-6">
                {{ block.heading_1.text[0].plain_text }}
              </h1>
            </div>
            <div v-if="block.type === 'heading_2'">
              <h2 class="is-size-3 is-size-4-mobile mt-6">
                {{ block.heading_2.text[0].plain_text }}
              </h2>
            </div>
            <div v-if="block.type === 'heading_3'">
              <h3 class="is-size-4 is-size-5-mobile mt-6">
                {{ block.heading_3.text[0].plain_text }}
              </h3>
            </div>
            <div v-if="block.type === 'paragraph'">
              <p class="is-size-6">
                <span
                  class=""
                  v-for="textpart in block.paragraph.text"
                  :key="textpart.plain_text + Math.random()"
                >
                  <span
                    class="has-text-weight-bold"
                    v-if="textpart.annotations.bold"
                    >{{ textpart.plain_text }}</span
                  >
                  <span
                    class="is-italic"
                    v-else-if="textpart.annotations.italic"
                    >{{ textpart.plain_text }}</span
                  >
                  <span
                    class="is-line-through"
                    v-else-if="textpart.annotations.strikethrough"
                    >{{ textpart.plain_text }}</span
                  >
                  <span
                    class="is-underlined"
                    v-else-if="textpart.annotations.underline"
                    >{{ textpart.plain_text }}</span
                  >
                  <code class="" v-else-if="textpart.annotations.code">{{
                    textpart.plain_text
                  }}</code>
                  <a
                    class=""
                    v-else-if="textpart.href"
                    v-bind:href="textpart.href"
                    target="_self"
                    >{{ textpart.plain_text }}</a
                  >
                  <span v-else>{{ textpart.plain_text }}</span>
                </span>
              </p>
            </div>
            <ul v-if="block.type === 'bulleted_list_item'">
              <li class="is-size-6">
                {{ block.bulleted_list_item.text[0].plain_text }}
              </li>
            </ul>
            <ul v-if="block.type === 'numbered_list_item'">
              <li class="is-size-6">
                {{ block.numbered_list_item.text[0].plain_text }}
              </li>
            </ul>
            <blockquote class="is-size-6" v-if="block.type === 'quote'">
              {{ block.quote.text[0].plain_text }}
            </blockquote>
            <article
              class="notification is-info"
              role="alert"
              v-if="block.type === 'callout'"
            >
              <div class="media">
                <div class="media-left">
                  <span
                    class="icon is-large is-size-4 is-size-5-mobile"
                    aria-hidden=""
                    v-if="block.callout.icon.type === 'emoji'"
                  >
                    {{ block.callout.icon.emoji }}
                  </span>
                  <span
                    class="icon is-large"
                    aria-hidden=""
                    v-if="block.callout.icon.type === 'file'"
                  >
                    <img :src="block.callout.icon.file.url" />
                  </span>
                </div>
                <div class="media-content is-size-6">
                  {{ block.callout.text[0].plain_text }}
                </div>
              </div>
            </article>
            <p class="is-size-6" v-if="block.type === 'to_do'">
              <b-icon
                icon="checkbox-blank-circle-outline"
                size="is-small"
                v-if="!block.to_do.checked"
              >
              </b-icon>
              <b-icon
                icon="checkbox-marked-circle-outline"
                size="is-small"
                v-if="block.to_do.checked"
              >
              </b-icon>
              &nbsp;{{ block.to_do.text[0].plain_text }}
            </p>
            <p class="is-size-6" v-if="block.type === 'bookmark'">
              <a
                class="is-size-6 button is-medium is-primary is-outlined is-inverted"
                :href="block.bookmark.url"
                target="_self"
                v-if="block.bookmark.caption.length != 0"
                ><b-icon icon="link-variant" size="is-small"></b-icon>
                &nbsp;&nbsp;&nbsp;{{ block.bookmark.caption[0].plain_text }}</a
              >
              <a
                class="is-size-6 button is-medium is-primary is-outlined is-inverted"
                :href="block.bookmark.url"
                target="_self"
                v-else
                ><b-icon icon="link-variant" size="is-small"> </b-icon>
                &nbsp;&nbsp;&nbsp;{{ block.bookmark.url }}</a
              >
            </p>
            <p class="is-size-6" v-if="block.type === 'file'">
              <b-icon icon="file-download-outline" size="is-small"> </b-icon
              >&nbsp;
              <a
                class="is-size-6"
                :href="block.file.file.url"
                target="_blank"
                v-if="
                  block.file.caption.length != 0 && block.file.type === 'file'
                "
                >{{ block.file.caption[0].plain_text }}</a
              >
              <a
                class="is-size-6"
                :href="block.file.file.url"
                target="_blank"
                v-else-if="
                  block.file.caption.length === 0 && block.file.type === 'file'
                "
                >{{ block.file.file.url }}</a
              >
              <a
                class="is-size-6"
                :href="block.file.external.url"
                target="_blank"
                v-if="
                  block.file.caption.length != 0 &&
                  block.file.type === 'external'
                "
                >{{ block.file.caption[0].plain_text }}</a
              >
              <a
                class="is-size-6"
                :href="block.file.external.url"
                target="_blank"
                v-else-if="
                  block.file.caption.length === 0 &&
                  block.file.type === 'external'
                "
                >{{ block.file.external.url }}</a
              >
            </p>
            <hr v-if="block.type === 'divider'" />
            <div v-if="block.type === 'image'">
              <b-image
                v-if="block.image.type === 'file'"
                :src="block.image.file.url"
                :placeholder="require(`@/assets/ImagePlaceholder.png`)"
                ratio="16by9"
              >
              </b-image>
              <b-image
                v-if="block.image.type === 'external'"
                :src="block.image.external.url"
                :placeholder="require(`@/assets/ImagePlaceholder.png`)"
                ratio="16by9"
              >
              </b-image>
              <figcaption v-if="block.image.caption.length !== 0">
                {{ block.image.caption[0].plain_text }}
              </figcaption>
            </div>
            <figure
              v-if="
                block.type === 'video' &&
                block.video.external.url.includes('youtu')
              "
              class="image is-16by9"
            >
              <iframe
                class="has-ratio"
                :src="
                  'https://www.youtube-nocookie.com/embed/' +
                  block.video.external.url.split('v=')[1].split('&')[0]
                "
                frameborder="0"
                allowfullscreen
              ></iframe>
            </figure>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import axios from "axios";

export default {
  name: "BlockContent",
  components: {},
  data() {
    return {
      data: [],
      closenotification: false,
      isLoading: true,
      isError: false,
    };
  },
  props: {
    notionpage: String,
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post(
          ".netlify/functions/readpagefull",
          this.notionpage,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.data = res.data;
          this.isLoading = false;
          this.$emit("notiondata", res.data);
        } else {
          //could not get Data
          this.isLoading = false;
          this.isError = true;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isLoading = false;
        this.isError = true;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss"></style>
