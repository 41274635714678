<template>
  <div class="pagecontent">
    <div class="block" v-if="notiondata.cover">
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn animate__slow"
        leave-active-class="animate__animated animate__fadeOut animate__slow"
      >
        <b-image
          v-if="notiondata.cover.type === 'file'"
          :src="notiondata.cover.file.url"
          :placeholder="require(`@/assets/ImagePlaceholder.png`)"
          ratio="4by1"
        >
        </b-image>
      </transition>
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeIn animate__slow"
        leave-active-class="animate__animated animate__fadeOut animate__slow"
      >
        <b-image
          v-if="notiondata.cover.type === 'external'"
          :src="notiondata.cover.external.url"
          :placeholder="require(`@/assets/ImagePlaceholder.png`)"
          ratio="4by1"
        >
        </b-image>
      </transition>
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn animate__slow"
      leave-active-class="animate__animated animate__fadeOut animate__slow"
    >
      <div class="block" v-if="notiondata.properties">
        <h1 class="is-size-1 is-size-2-mobile has-text-centered mt-6">
          {{ notiondata.properties.Name.title[0].plain_text }}
        </h1>
      </div>
    </transition>
  </div>
</template>

<script type="text/babel">
export default {
  name: "FullPageContent",
  components: {},
  data() {
    return {};
  },
  props: {
    notiondata: [Array, Object],
  },
  methods: {},
};
</script>

<style lang="scss"></style>
